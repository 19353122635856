import React, {useEffect, useState} from "react";
import {Avatar, Button, Col, Image, Layout, Menu, Popover, Row, Typography} from "antd";
import {Link} from "react-router-dom";
import {getSideBarListApi} from "../api/main-menu.api";
import Logo from '../images/avo.png'
import userDummyImage from '../images/Avatar.svg'
import {Colors} from "../ui-util/colors";

const {Text} = Typography;

const {Sider, Footer} = Layout;
const {SubMenu} = Menu;

const SideBar = () => {
    const token = localStorage.getItem('TOKEN_AUTH');
    const [sideBarList, setSideBarList] = useState([])
    const [ellipsis] = useState(true)
    const [openKeys, setOpenKeys] = useState([])
    const pathRaw = window.location.pathname.split('/')
    const pathName = pathRaw[1]

    useEffect(() => {
        getSideBarListApi()
            .then(response => {
                setSideBarList(response)
            })
            .catch(err => {
                console.log('Err', err)
                if (err.code === 401 && !!token) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])

    useEffect(() => {
        setOpenKeys([pathName])
    }, [])

    const content = (
        <Col>
            <Button
                onClick={() => {
                    localStorage.removeItem('TOKEN_AUTH')
                    window.location.href = '/'
                }}
                style={{
                    width: 150,
                    borderColor: Colors.palevioletred,
                    backgroundColor: Colors.seashell,
                    borderRadius: 12,
                    color: Colors.palevioletred
                }}>Logout</Button>
        </Col>
    );

    const isNotEmbedPage = (slug) => {
        switch (slug) {
            case 'menu-setting':
            case 'user-setting':
            case 'analytics':
            case 'email':
                return true
            default:
                return false
        }
    }

    return (
        <div style={{paddingTop: 20}}>
            {
                !!token &&
                <Sider
                    width={250}
                    style={{}}>
                    <div style={{backgroundColor: 'white'}}>
                        <Row justify={'center'} style={{alignItems: 'center', paddingTop: 40}}>
                            <Image src={Logo} preview={false} style={{width: 100, height: 100}}/>
                        </Row>
                        <Menu
                            defaultOpenKeys={[pathName]}
                            onOpenChange={(keys) => {
                                setOpenKeys(keys)
                            }}
                            mode="inline" style={{
                            marginLeft: 20,
                            marginRight: 20,
                            width: 200,
                            marginTop: 40,
                            minHeight: window.screen.height - 350,
                            borderWidth: 0
                        }}>
                            {
                                sideBarList.map((item, index) => {
                                    return (
                                        <SubMenu key={item.slug}
                                                 title={<Text
                                                     style={{color: openKeys.includes(item.slug) ? 'white' : Colors.armyGray}}
                                                     ellipsis={ellipsis && {tooltip: item.name}}>{item.name}</Text>}
                                                 multiple={true}
                                                 style={{
                                                     backgroundColor: openKeys.includes(item.slug) ? Colors.armyGray : 'white',
                                                     borderRadius: 10
                                                 }}
                                        >
                                            {
                                                item.menus.map((menu) => {
                                                    if (menu.embed_urls.length > 0) {
                                                        return (
                                                            <SubMenu key={menu.slug} title={<Text
                                                                ellipsis={ellipsis && {tooltip: item.name}}>{menu.name}</Text>}
                                                                     multiple={true}
                                                                     style={{color: 'red'}}
                                                            >
                                                                {
                                                                    menu.embed_urls.map(url => (
                                                                        <Menu.Item key={url.slug}>
                                                                            <Link
                                                                                to={isNotEmbedPage(item.slug) ? `/${menu.slug}` : `/sub-menu/power-bi/${url.slug}`
                                                                                } style={{
                                                                                textDecoration: 'none',
                                                                                color: Colors.armyGray
                                                                            }}>
                                                                                {url.name}
                                                                            </Link>
                                                                        </Menu.Item>
                                                                    ))
                                                                }
                                                            </SubMenu>
                                                        )
                                                    } else {
                                                        return (
                                                            <Menu.Item key={menu.slug}>
                                                                <Link
                                                                    to={isNotEmbedPage(item.slug) ? `/${menu.slug}` : `/menu/power-bi/${menu.slug}`}
                                                                    style={{
                                                                        textDecoration: 'none',
                                                                        color: Colors.armyGray
                                                                    }}>
                                                                    {menu.name}
                                                                </Link>
                                                            </Menu.Item>
                                                        )
                                                    }
                                                })
                                            }
                                        </SubMenu>
                                    )
                                })
                            }
                        </Menu>
                        <Footer style={{
                            backgroundColor: 'white',
                        }}>
                            <Popover placement="topLeft" title={<div style={{color: Colors.armyGray}}>Welcome</div>}
                                     content={content} trigger="click"
                                     overlayInnerStyle={{borderRadius: 12}}>
                                <Row justify={'space-between'}>
                                    <Avatar size="large" src={userDummyImage}/>
                                    <div style={{width: '65%'}}>
                                        <div style={{
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            color: Colors.armyGray,
                                        }}>Admin
                                        </div>
                                        <div style={{fontSize: 16, color: Colors.armyGray}}>Logout</div>
                                    </div>
                                </Row>
                            </Popover>
                        </Footer>
                    </div>
                </Sider>
            }
        </div>
    )
}

export default SideBar
