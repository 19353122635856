import React from "react";
import {Button, Col, Image, Row} from "antd";
import {clientId, stringToBase64} from "../../ui-util/general-variable";
import {GoogleLogin} from "react-google-login";
import {signInAuthApi} from "../../api/auth.api";
import Logo from '../../images/avo.png'
import {Colors} from "../../ui-util/colors";

const LandingPage = () => {

    const responseGoogle = async (response) => {
        try {
            const result = await signInAuthApi({email: response?.profileObj?.email})
            let roleBase64 = stringToBase64(result?.admin_detail?.role?.name)
            localStorage.setItem('TOKEN_AUTH', result.token)
            localStorage.setItem('ROLE', roleBase64)
            window.location.reload()
        } catch (e) {
            localStorage.removeItem('TOKEN_AUTH')
            console.log('Error userSignInApi', e)
            alert('Maaf akun anda tidak terdaftar sebagai admin!')
        }
    }

    return (

        <div
            style={{
                top: 0,
                display: 'flex',
                position: "absolute",
                width: "100%",
                height: "100%",
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <div className={'login'} style={{backgroundColor: 'white', height: '80vh', width: '30%'}}>
                <Col>
                    <Row style={{alignItems: 'center', justifyContent: 'center'}}>
                        <Image src={Logo} preview={false} style={{width: 300, height: 300}}/>
                    </Row>
                    <div style={{
                        color: Colors.darkSeaGreen,
                        textAlign: 'center',
                        fontSize: 28,
                        marginTop: 30,
                        marginBottom: 100
                    }}>Avoskin Intelligence Monitoring
                    </div>
                    <div style={{
                        color: Colors.darkSeaGreen,
                        textAlign: 'center',
                        marginBottom: 20,
                    }}>Login with your mail here
                    </div>
                    <div className="center">
                        <GoogleLogin
                            render={renderProps => <Button onClick={renderProps.onClick} style={{
                                backgroundColor: Colors.darkSeaGreen,
                                width: 200,
                                height: 40,
                                color: 'white',
                                borderRadius: 12
                            }}>Login</Button>}
                            clientId={clientId()}
                            onSuccess={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </Col>
            </div>
        </div>
    )
}

export default LandingPage
